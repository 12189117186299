import { forwardRef } from "react";
import ChevronIcon from "../../assets/icons/ChevronIcon";
import Dropdown from 'react-bootstrap/Dropdown';

import { ReactComponent as CheckMarkIcon } from "../../assets/icons_svg/check-mark-outline.svg";
import { ReactComponent as ArchiveIcon } from "../../assets/icons_svg/archive.svg";

import './CategoryStatusDropdown.scss';

export default function CategoryStatusDropdown({ category, onToggleArchive }) {
  const renderLabel = archived => (
    archived
    ? <div className="label-archived"><ArchiveIcon /><span>Archived</span></div>
    : <div className="label-active"><CheckMarkIcon /><span>Active</span></div>
  );
  
  const renderContent = () => (
    <div className='label'>{ renderLabel(category.archived) }</div>
  );
  
  return (
    <Dropdown className='__category-status-dropdown'>
      <Dropdown.Toggle as={CategoryStatusToggle} className='status-toggle'>
        <div className="toggle-overflow">
          { renderContent() }
          <ChevronIcon className='chevron' />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item as='button' onClick={category.archived ? onToggleArchive : null}>
          Active
        </Dropdown.Item>
        <Dropdown.Item as='button' onClick={category.archived ? null : onToggleArchive }>
          Archived
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );  
}

const CategoryStatusToggle = forwardRef(({ children, onClick, ...props }, ref) => (
  <button onClick={onClick} ref={ref} {...props}>
    { children }
  </button>
));
