import { useState } from "react"
import { useDebouncedCallback } from "use-debounce";

const DEFAULT_DURATION = 2000;

export default function useToast(props) {

  props = { duration: DEFAULT_DURATION, ...props };

  const [toastMessage, setToastMessage] = useState('');
  const [toastDuration, setToastDuration] = useState(props.duration);
  const [show, setShow] = useState(false);

  const showToast = (message, duration) => {
    hideSavedToast.cancel();
    setShow(true);
    setToastMessage(message);
    hideSavedToast();
    duration && setToastDuration(duration);
  }

  const hideSavedToast = useDebouncedCallback(() => {
    setShow(false);
  }, [toastDuration]);

  const toast = {
    message: toastMessage,
    show,
  }

  return {
    toast,
    showToast,
  }
}
