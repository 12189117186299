import { useSearchParams } from "react-router-dom";

export default function useGlobalParams() {
  const [searchParams] = useSearchParams();

  const organizationId = parseInt(searchParams?.get('organization_id', 10)) || null
  const businessId = parseInt(searchParams?.get('business_id', 10)) || null;

  return { organizationId, businessId }
}
