import { forwardRef } from "react";
import ChevronIcon from "../../assets/icons/ChevronIcon";
import Dropdown from 'react-bootstrap/Dropdown';
import useStatuses from "../../hooks/useStatuses";

import './StatusDropdown.scss';

export default function StatusDropdown({ categoryId, selectedId, onChange }) {
  const { data: statuses, isLoading } = useStatuses({ categoryId });
  const status = statuses?.find(status => status.id === selectedId);
  
  if (isLoading) {
    return;
  }

  const renderContent = () => {
    if (status) {
      return (
        <>
          <div className='square' style={{ backgroundColor: `#${status.color}` }} />
          <div className='label'>{ status.name }</div>
        </>
      );
    }

    return (
      <div className='label label-placeholder'>(Select a status)</div>
    );
  }

  return (
    <Dropdown className='__status-dropdown'>
      <Dropdown.Toggle as={StatusToggle} className='status-toggle'>
        <div className="toggle-overflow">
          { renderContent() }
          <ChevronIcon className='chevron' />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        { statuses.sort((a, b) => a.order_index - b.order_index).map(item => (
          <Dropdown.Item key={item.id} as='button' onClick={() => onChange(item.id)}>
            <div className='square' style={{ backgroundColor: `#${item.color}` }} />
            { item.name }
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

const StatusToggle = forwardRef(({ children, onClick, ...props }, ref) => (
  <button onClick={onClick} ref={ref} {...props}>
    { children }
  </button>
));
