import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "../utils/api";
import { produce } from "immer";

const normalizeBulkIds = ids => ids.map(id => ({ id }));

const addLead = ({ data }) => api.post(`/leads/`, { ...data }).then(res => res.data);
const updateLead = ({ id, data }) => api.put(`/leads/${id}/`, { ...data }).then(res => res.data);
const undoDeleteLead = ({ lead }) => api.put(`/leads/${lead.id}/`, { is_deleted: false }).then(res => res.data);
const deleteLead = id => api.delete(`/leads/${id}/`).then(res => res.data);
const deleteBulk = ids => api.delete(`/leads/`, { data: normalizeBulkIds(ids) }).then(res => res.data);

export default function useMutateLead() {
  const queryClient = useQueryClient();

  const onMutateLead = variables => {
    queryClient.setQueriesData(['leads'], data => produce(data, draft => {
      const index = data.findIndex(lead => lead.id === variables.id);

      if (index !== -1) {
        draft[index] = { ...data[index], ...variables.data };
      }
    }));
  }

  const onUpdateSuccess = (requestData, { id }) => {
    queryClient.setQueriesData(['leads'], data => produce(data, draft => {
      const index = data.findIndex(lead => lead.id === id);
      draft[index] = requestData;
    }));
  }

  const onAddLead = requestData => {
    queryClient.setQueriesData(['leads'], data => produce(data, draft => {
      draft.unshift(requestData);
    }));
  }

  const onBulkDelete = ids => {
    queryClient.setQueriesData(['leads'], data => produce(data, draft => draft.filter(lead => !ids.includes(lead.id))));
  }

  const onDelete = id => {
    queryClient.setQueriesData(['leads'], data => produce(data, draft => {
      const index = draft.findIndex(lead => lead.id === id);
      draft.splice(index, 1);
    }));
  }

  const onUndoDelete = ({ lead, index }) => {
    queryClient.setQueriesData(['leads'], data => produce(data, draft => {
      draft.splice(index, 0, lead);
    }));
  }

  return {
    updateLead         : useMutation({ mutationFn: updateLead, onSuccess: onUpdateSuccess }),
    updateLeadAsync    : useMutation({ mutationFn: updateLead, onMutate: onMutateLead }),
    undoDeleteMutation : useMutation({ mutationFn: undoDeleteLead, onMutate: onUndoDelete }),
    insertMutation     : useMutation({ mutationFn: addLead, onSuccess: onAddLead }),
    deleteMutation     : useMutation({ mutationFn: deleteLead, onMutate: onDelete }),
    bulkDeleteMutation : useMutation({ mutationFn: deleteBulk, onMutate: onBulkDelete }),
  }
}
