import { useEffect } from 'react';
import { useImmer } from 'use-immer';
import classNames from 'classnames';
import useMutateLead from '../../hooks/useMutateLead';
import useStatuses from '../../hooks/useStatuses';
import useLeadNotes from '../../hooks/useLeadNotes';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import { Spinner } from 'react-bootstrap';
import LeadNote from './LeadNote';

import './LeadDetailsModal.scss';

const FIELDS_MAIN = [
  'full_name',
  'first_name',
  'last_name',
  'email',
  'phone',
  'status',
  'gender',
  'birthdate',
];

const FIELDS_ADDRESS = [
  'country',
  'city',
  'state',
  'zip_code',
  'street_address',
];

export default function LeadDetailsModal({ show, onHide, lead, categoryId }) {
  const [editedLead, setEditedLead] = useImmer({});
  const { updateLead, insertMutation } = useMutateLead();
  const { data: statuses } = useStatuses({ categoryId });
  const { data: leadNotes } = useLeadNotes({ leadId: lead?.id });

  // TODO -> there is a bug. if the lead refreshes, the values will be updated
  // this might be bad UX. Maybe only update the fields that haven't been edited?
  // complex but good
  useEffect(() => {
    if (show) {
      setEditedLead(lead || {});
    }
  }, [show, setEditedLead, lead]);

  const onSubmit = () => {
    const data = {};

    [...FIELDS_MAIN, ...FIELDS_ADDRESS, 'status_id'].forEach(field => {
      if (lead?.[field] !== editedLead[field]) {
        data[field] = editedLead[field];
      }
    });

    // check if custom fields are different. If so, add them:
    editedLead.custom_fields && Object.entries(editedLead.custom_fields).forEach(([key, value]) => {
      if (lead?.custom_fields[key] !== value) {
        data[key] = value;
      }
    });

    // ignore for now
    // if (!data.status) {
    //   data.status = statuses.find(status => status.code === 'new').id;
    // }
    
    if (!lead) {
      data.category_id = categoryId;
      insertMutation.mutate({ data }, { onSuccess: onHide });
    }
    else {
      Object.entries(data).length > 0 && updateLead.mutate({ id: lead.id, data }, { onSuccess: onHide });
    }
  }

  const renderStatus = () => {
    if (!statuses) {
      return;
    }

    const status = statuses.find(status => status.id === editedLead.status_id) || statuses.find(status => status.code === 'new');

    return (
      <Dropdown className='status-dropdown'>
        {/* <Dropdown.Toggle as={StatusToggle} className={classNames('status-toggle', `color-${status.code}`)}> */}
        <Dropdown.Toggle className={classNames('status-toggle', `color-${status?.code}`)}>
          { status?.name || '(No status assigned)' }
          {/* <div className="toggle-overflow">
            <div className='label'>{ status?.label }</div>
            <ChevronIcon className='chevron' />
          </div> */}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          { statuses.map(statusOptions => (
            <Dropdown.Item key={statusOptions.id} as='button' onClick={() => setEditedLead(draft => { draft.status_id = statusOptions.id })}>
              { statusOptions.name }
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  const renderField = field => (
    <div key={field} className='field'>
      <label>{ field.replace('_', ' ') }</label>
      { field === 'status'
      ? statuses && renderStatus()
      : <Form.Control type='text' value={editedLead[field] || ''} onChange={e => setEditedLead(draft => { draft[field] = e.target.value })} />
      }
    </div>
  );

  const loading = insertMutation.isLoading || updateLead.isLoading;

  const onChangeCustomField = (e, key) => {
    setEditedLead(draft => {
      draft.custom_fields[key] = e.target.value;
    });
  }

  return (
    <Modal className='__lead-details-modal' show={show} onHide={onHide} centered size='lg'>
      <Modal.Header onHide={onHide} closeButton>Lead Details</Modal.Header>
      <Modal.Body>
        <div className='field-columns'>
          <div>{ FIELDS_MAIN.map(field => renderField(field)) }</div>
          <div>
            { FIELDS_ADDRESS.map(field => renderField(field)) }
          </div>
          <div>
            <h3>Custom Fields</h3>
            { editedLead?.custom_fields && Object.entries(editedLead.custom_fields).map(([key, value]) => (
              <Form className='my-2' key={key}>
                <Form.Label>{ key }</Form.Label>
                <Form.Control value={value} onChange={e => onChangeCustomField(e, key)} />
              </Form>
            )) }

            <h3>Notes</h3>
            { leadNotes?.map(note => <LeadNote key={note.id} leadId={lead.id} note={note} />) }
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Cancel</Button>
        <Button onClick={onSubmit} disabled={loading}>
          { loading
          ? <><Spinner size='sm' />&nbsp; Saving</>
          : 'Save'
          }
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
