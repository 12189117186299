import { useMutation, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";
import { api } from "../utils/api";

const apiCreate = ({ data }) => api.post(`/categories/`, { ...data }).then(res => res.data);
const apiDelete = id => api.delete(`/categories/${id}/`).then(res => res.data);
const apiUpdate = ({ data, id }) => api.put(`/categories/${id}/`, { ...data }).then(res => res.data);

const apiBulkUpdate = ({ data }) => api.put(`/categories/`, data).then(res => res.data);

export default function useMutateCategory() {
  const queryClient = useQueryClient();

  const onUpdate = ({ data: newData, id }) => {
    let previousItem = null;

    queryClient.setQueriesData(['categories'], data => produce(data, draft => {
      if (Array.isArray(data)) {
        const index = data.findIndex(status => status.id === id);
        draft[index] = {...data[index], ...newData };

        previousItem ||= data[index];
      }

      else {
        previousItem ||= data;
        return { ...data, ...newData };
      }
    }));

    return { previousItem, id }
  }

  const onUpdateSync = (requestData, { id }) => {
    queryClient.setQueriesData(['categories'], data => produce(data, draft => {
      if (Array.isArray(data)) {
        const index = data.findIndex(status => status.id === id);
        draft[index] = requestData;
      }

      else {
        return requestData;
      }
    }));
  }

  const onUpdateError = (err, newData, context) => {
    const { previousItem, id } = context;

    queryClient.setQueriesData(['categories'], data => produce(data, draft => {
      if (Array.isArray(data)) {
        const index = data.findIndex(status => status.id === id);
        draft[index] = previousItem;
      }

      else {
        return previousItem;
      }
    }))
  }

  const onBulkUpdate = ({ data: newData }) => {
    queryClient.setQueriesData(['categories'], data => produce(data, draft => {
      if (Array.isArray(data)) {
        newData.forEach(newItem => {
          const index = data.findIndex(item => item.id === newItem.id);
          draft[index] = { ...data[index], ...newItem };
        });
      }
    }));
  }

  const onCreate = requestData => {
    queryClient.setQueriesData(['categories'], data => produce(data, draft => {
      if (Array.isArray(data)) {
        draft.push(requestData);
      }
    }));
  }

  const onDelete = id => {
    queryClient.setQueriesData(['categories'], data => produce(data, draft => {
      if (Array.isArray(data)) {
        const index = data.findIndex(category => category.id === id);
        draft.splice(index, 1);
      }
    }));
  }

  return {
    updateCategory       : useMutation({ mutationFn: apiUpdate, onMutate: onUpdate, onError: onUpdateError }),
    updateCategorySync   : useMutation({ mutationFn: apiUpdate, onSuccess: onUpdateSync }),
    createCategory       : useMutation({ mutationFn: apiCreate, onSuccess: onCreate }),
    deleteCategory       : useMutation({ mutationFn: apiDelete, onMutate: onDelete }),
    bulkUpdateCategories : useMutation({ mutationFn: apiBulkUpdate, onMutate: onBulkUpdate }),
  }
}