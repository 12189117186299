import { useEffect, useState } from 'react';
import useMutateRole from '../../hooks/useMutateRole';
import { handleNetworkError } from '../../utils/api';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from '../../common/Button/Button';

export default function UserRoleModal({ role, user, onHide, show, organizationId, ...props}) {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [validated, setValidated] = useState(false);

  const { createInvitation, updateUserRole } = useMutateRole();

  useEffect(() => {
    if (show) {
      setEmail(user?.email || role?.invitation_email || '');
      setErrors(null);
      setValidated(false);
    }
  }, [role, show, user]);

  const onSubmit = async e => {

    e.preventDefault();

    setLoading(true);
    setErrors(null);
    setValidated(false);

    const data = {
      invitation_email : email,
      organization_id  : organizationId,
      role             : "admin",
    };

    const onError = e => handleNetworkError({ e, setErrors, setValidated });
    const onSettled = () => setLoading(false);
    const onSuccess = () => onHide();

    if (!role) {
      createInvitation.mutate({ data, organizationId }, { onError, onSettled, onSuccess });
    }
    else {
      updateUserRole.mutate({ data, id: role.id }, { onError, onSettled, onSuccess });
    }
  }

  return (
    <Modal className='__user-role-modal __modal' show={show} {...props}>
      <Modal.Header>Add User</Modal.Header>
      <Form noValidate validated={validated && !errors} onSubmit={onSubmit}>
      <Modal.Body>
        <div>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control disabled={user} type='email' value={email} onChange={e => setEmail(e.target.value)} isValid={validated && !errors?.invitation_email} isInvalid={errors?.invitation_email} />
            { errors?.invitation_email?.map(error => <Form.Control.Feedback type='invalid' key={error}>{ error } </Form.Control.Feedback>)}
          </Form.Group>

          <p><br /><br />---------------------------<br />User will be ORG. ADMIN<br />---------------------------</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button type='button' className='cancel' onClick={onHide} disabled={loading}>Cancel</Button>
        <Button type='submit' className='confirm' onClick={onSubmit} loading={loading}>{ role ? 'Save' : 'Add User' }</Button>
      </Modal.Footer>
      </Form>
    </Modal>
  )
}
