import { useEffect, useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from '../Button/Button';

import './ConfirmationModal.scss';

export default function ConfirmationModal({ title, show, children, cancelText, inputConfirm, confirmText, onHide, onConfirm, ...props }) {
  const [confirmValue, setConfirmValue] = useState('');

  useEffect(() => {
    if (show) {
      setConfirmValue('');
    }
  }, [show]);

  const confirmDisabled = inputConfirm && !['yes', '"yes"'].includes(confirmValue.trim());

  const onCofirmForm = e => {
    e.preventDefault();
    onConfirm();
  }

  const innerContent = () => (
    <>
      <Modal.Body>
        { children }

        { inputConfirm && (
          <>
            <p>To confirm, type "yes" in the field below.</p>
            <Form.Control type='text' value={confirmValue} onChange={e => setConfirmValue(e.target.value)} />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button type='button' className='cancel' onClick={onHide}>{ cancelText || 'Cancel' }</Button>
        <Button type='submit' className='confirm' disabled={confirmDisabled} onClick={inputConfirm ? null : onConfirm}>{ confirmText || 'Confirm' }</Button>
      </Modal.Footer>
    </>
  );

  const modalContent = () => inputConfirm ? <Form onSubmit={onCofirmForm}>{ innerContent() }</Form> : innerContent();

  return (
    <Modal className="__confirmation-modal __modal" onHide={onHide} show={show} {...props} backdrop='static' centered>
      <Modal.Header onHide={onHide} closeButton><h2>{ title }</h2></Modal.Header>
      { modalContent() }
    </Modal>  
  );
}
