import { useEffect, useState } from "react";
import useMutateLead from "../../hooks/useMutateLead";

import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';

import PencilIcon from "../../assets/icons/PencilIcon";
import CheckIcon from "../../assets/icons/CheckIcon";
import XIcon from "../../assets/icons/XIcon";

import './EditableField.scss';

export default function EditableField({ id, field, initialValue }) {
  const [value, setValue] = useState('');
  const [editing, setEditing] = useState(false);

  const { updateLeadAsync } = useMutateLead();

  const handleCancel = () => {
    setValue(initialValue);
    setEditing(false);
  }

  const handleSave = e => {
    e.preventDefault();

    const data = field === 'first_last_name' ? { first_name: value[0], last_name: value[1] } : { [field]: value };

    updateLeadAsync.mutate({ id, data });
    setEditing(false);
  }

  useEffect(() => {
    // if the initialValue is changed by another mutation, this should
    // be reflected in the label or input to avoid desynced data:
    setValue(initialValue);
  }, [initialValue]);

  const inputType = field === 'email' ? 'email' : 'text';

  const renderInputField = () => {
    if (field === 'first_last_name') {
      return (
        <>
          <input type={inputType} value={value[0]} onChange={e => setValue([e.target.value, value[1]])} autoFocus maxLength={254} />
          <div className='field-divider'> / </div>
          <input type={inputType} value={value[1]} onChange={e => setValue([value[0], e.target.value])} autoFocus maxLength={254} />
        </>
      );
    }
    else {
      return (
        <div className="input-container">
          <input type={inputType} value={value} onChange={e => setValue(e.target.value)} autoFocus maxLength={254} />
        </div>
      );
    }
  }

  if (editing) {
    return (
      <form onSubmit={handleSave} className='__editable-field editing' data-interactive>
        { field === 'full_name' && <div className='field-label'>Full Name</div>}
        { field === 'first_last_name' && (
          <>
            <div className='field-label'>First Name</div>
            <div className='field-label last-field'>Last Name</div>
          </>
        )}
        { renderInputField() }
        <div className='actions'>
          <button type='submit'><CheckIcon className='save' /></button>
          <button><XIcon className='close' onClick={handleCancel} /></button>
        </div>
      </form>
    );
  }

  const hasValue = Array.isArray(value) ? (value[0]?.trim() || value[1]?.trim()) : value?.trim();

  return (
    <form className='__editable-field'>
      { hasValue
      ? <div className='field'>{ Array.isArray(value) ? `${value[0]} ${value[1]}` : value }</div> 
      : <Button variant='link' onClick={() => setEditing(true)} className='empty-field-add'>+ Add</Button>
      }
      { updateLeadAsync.isLoading
      ? <Spinner className='saving' size="sm" />
      : hasValue && <button className='edit' onClick={() => setEditing(true)}><PencilIcon /></button>
      }
    </form>
  );
}
