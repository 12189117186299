import { useState } from "react";

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import useMutateLeadNotes from "../../hooks/useMutateLeadNotes";

export default function LeadNote({ leadId, note }) {
  const [editing, setEditing] = useState(false);
  const [text, setText] = useState(null);

  const { updateLeadNote, deleteLeadNote } = useMutateLeadNotes({ leadId });

  const onEditNote = () => {
    setEditing(true);
    setText(note.note_text);
  }
  
  const onSave = e => {
    e.preventDefault();

    const data = {
      note_text: text
    }

    updateLeadNote.mutate({ data, id: note.id });
  }

  const onDeleteNote = () => {
    deleteLeadNote.mutate(note.id);
  }

  if (editing) {
    return (
      <div>
        <Form onSubmit={onSave}>
          <Form.Control className='my-2' as='textarea' value={text} onChange={e => setText(e.target.value)} />
          <Button type='submit'>Save</Button>
          <Button className="mx-1" onClick={() => setEditing(false)}>Cancel</Button>
        </Form>
      </div>
    );
  }
  else {
    return (
      <div>
        <Form.Control className='my-2' as='textarea' value={note.note_text} readOnly />
        <Button onClick={onEditNote}>Edit</Button>
        <Button onClick={onDeleteNote}>Delete</Button>
      </div>
    );
  }
}
