import { useQuery } from "@tanstack/react-query";
import { api } from "../utils/api";

const getLeadNotes = ({ queryKey }) => {
  const [, leadId] = queryKey;

  if (!leadId) {
    return null;
  }

  return api.get(`/lead-notes/?lead_id=${leadId}`).then(res => res.data);
}

export default function useLeadNotes({ leadId, ...props }) {
  return useQuery({ queryKey: ['lead-notes', leadId], queryFn: getLeadNotes, ...props });
}
