import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "../utils/api";
import { produce } from "immer";

const apiCreate = ({ data }) => api.post('/businesses/', {...data}).then(res => res.data);
const apiUpdate = ({ data, id }) => api.put(`/businesses/${id}/`, { ...data }).then(res => res.data);
const apiDelete = id => api.delete(`/businesses/${id}/`).then(res => res.data);

export default function useMutateBusiness() {
  const queryClient = useQueryClient();

  const onUpdate = async ({ data: newData, id }) => {
    await queryClient.cancelQueries({ queryKey: ['businesses', id] });

    // Snapshot the previous value
    const previousItem = queryClient.getQueryData(['businesses', id]);

    queryClient.setQueriesData(['businesses'], data => produce(data, draft => {
      const index = data.findIndex(business => business.id === id);
      draft[index] = {...data[index], ...newData };
    }));

    return { previousItem, id }
  }

  // only updating single entry. For now it's okay
  const onUpdateError = (err, newData, context) => {
    queryClient.setQueryData(['businesses', context.id], context.previousItem)
  }

  const onCreate = requestData => {
    queryClient.setQueriesData(['businesses'], data => produce(data, draft => {
      draft.unshift(requestData);
    }));
  }

  const onDelete = id => {
    queryClient.setQueriesData(['businesses'], data => produce(data, draft => {
      const index = data.findIndex(business => business.id === id);
      draft.splice(index, 1);
    }));
  }

  return {
    updateBusiness: useMutation({ mutationFn: apiUpdate, onMutate: onUpdate, onError: onUpdateError }),
    createBusiness: useMutation({ mutationFn: apiCreate, onSuccess: onCreate }),
    deleteBusiness: useMutation({ mutationFn: apiDelete, onMutate: onDelete }),
  }
}
