import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "../utils/api";
import { produce } from "immer";

const apiCreate = ({ data, id }) => api.post(`/lead-notes/${id}`, { ...data }).then(res => res.data);
const apiUpdate = ({ data, id }) => api.put(`/lead-notes/${id}/`, { ...data }).then(res => res.data);
const apiDelete = id => api.delete(`/lead-notes/${id}/`).then(res => res.data);

export default function useMutateLeadNotes({ leadId }) {
  const queryClient = useQueryClient();

  const onCreate = requestData => {
    queryClient.setQueriesData(['lead-notes', leadId], data => produce(data, draft => {
      draft.unshift(requestData);
    }));
  }

  const onUpdate = requestData => {
    queryClient.setQueriesData(['lead-notes', leadId], data => produce(data, draft => {
      const index = data.findIndex(note => note.id === requestData.id);
      draft[index] = requestData;
    }));
  }

  const onDelete = id => {
    queryClient.setQueriesData(['lead-notes', leadId], data => produce(data, draft => {
      const index = data.findIndex(note => note.id === id);
      draft.splice(index, 1);
    }));
  }

  return {
    createLeadNote : useMutation({ mutationFn: apiCreate, onSuccess: onCreate }),
    updateLeadNote : useMutation({ mutationFn: apiUpdate, onSuccess: onUpdate }),
    deleteLeadNote : useMutation({ mutationFn: apiDelete, onMutate: onDelete }),
  }
}