import React, { useMemo, useRef } from "react";
import useStatuses from "../../hooks/useStatuses";
import { DateTime } from "luxon";
import Dropdown from 'react-bootstrap/Dropdown';
import classNames from "classnames";
import EditableField from "./EditableField";
import useMutateLead from "../../hooks/useMutateLead";
import Form from 'react-bootstrap/Form';

import ChevronIcon from "../../assets/icons/ChevronIcon";
import TrashIcon from "../../assets/icons/TrashIcon";

export default function LeadRow({ lead, selected, categoryId, onToggleChange, onClickRow, onDeleteLead }) {

  const toggleRef = useRef();
  const canToggleClick = useRef();

  const { data: statuses } = useStatuses({ categoryId });

  const { updateLeadAsync } = useMutateLead();

  const handleEditLead = data => {
    updateLeadAsync.mutate({ id: lead.id, data });
  }

  const dateAdded = useMemo(() => {
    return DateTime.fromISO(lead.date_added);
  }, [lead.date_added]);

  const onClickToggleContainer = e => {
    if (e.target === e.currentTarget) {
      toggleRef.current.click();
    }
  }

  const checkMouseDown = e => {
    const { target, currentTarget } = e;

    let isInteractiveElement = false;
    let element = target;
    
    while (element !== currentTarget) {
      if (['BUTTON', 'INPUT'].includes(element.tagName) || element.dataset.interactive === 'true') {
        isInteractiveElement = true;
        break;
      }

      element = element.parentElement;
    }

    // this prevents user from mousedown (start click) on an input,
    // but finishing the user "click" inside an empty non-interactive part of the row
    // to reproduce, dragging the mouse from an input to outside of the input:
    canToggleClick.current = !isInteractiveElement;
  }
  
  const checkClickRow = e => {
    const { target, currentTarget } = e;
    
    if (!canToggleClick.current) {
      return;
    }
    
    let isInteractiveElement = false;
    let element = target;
    
    while (element !== currentTarget) {
      if (['BUTTON', 'INPUT'].includes(element.tagName) || element.dataset.interactive === 'true') {
        isInteractiveElement = true;
        break;
      }

      element = element.parentElement;
    }
    
    !isInteractiveElement && onClickRow(lead.id);
  }

  const renderLeadStatus = lead => {
    if (!statuses) {
      return <div>...</div>;
    }

    const status = statuses?.find(status => status.id === lead.status_id);

    return (
      status && (
        <Dropdown className='status-dropdown'>
          <Dropdown.Toggle as={StatusToggle} className='status-toggle'>
            <div className="toggle-overflow">
              <div className='square' style={{ backgroundColor: `#${status.color}` }} />
              <div className='label'>{ status.name }</div>
              <ChevronIcon className='chevron' />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            { statuses.map(statusOptions => (
              <Dropdown.Item key={statusOptions.id} as='button' onClick={() => handleEditLead({ status_id: statusOptions.id })} className={`color-${statusOptions.code}`}>
                <div className='square' style={{ backgroundColor: `#${statusOptions.color}` }} />
                { statusOptions.name }
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )
    );
  }
  
  return (
    <tr key={lead.id} className={classNames({ selected })} onClick={checkClickRow} onMouseDown={checkMouseDown}>
      <td className='bulk-action'>
        <div className={classNames('toggle-container', { selected })} role='button' onClick={onClickToggleContainer} data-interactive>
          <Form.Check ref={toggleRef} type='checkbox' className='bulk-toggle' checked={selected} onChange={onToggleChange} />
        </div>
      </td>
      <td className='name'><EditableField id={lead.id} field={lead.full_name ? 'full_name' : 'first_last_name'} initialValue={lead.full_name || [lead.first_name, lead.last_name]} /></td>
      <td className='email'><EditableField id={lead.id} field='email' initialValue={ lead.email } /></td>
      <td className='phone'><EditableField id={lead.id} field='phone' initialValue={ lead.phone } /></td>
      <td className='status'>{ renderLeadStatus(lead) }</td>
      <td className='assignee'> - </td>
      <td className='date-added'>{ dateAdded.toLocaleString(DateTime.DATE_MED) }</td>
      <td className='delete-lead'><button className='delete-btn' onClick={onDeleteLead}><TrashIcon /></button></td>
    </tr>
  )
}

const StatusToggle = React.forwardRef(({ children, onClick, ...props }, ref) => (
  <button onClick={onClick} ref={ref} {...props}>
    { children }
  </button>
));
